@font-face {
  font-family: "IBM Plex Serif";
  src: url("ibmplexserif-regular-webfont.woff2") format("woff2"),
    url("ibmplexserif-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: url("ibmplexserif-italic-webfont.woff2") format("woff2"),
    url("ibmplexserif-italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: url("ibmplexserif-medium-webfont.woff2") format("woff2"),
    url("ibmplexserif-medium-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: url("ibmplexserif-bold-webfont.woff2") format("woff2"),
    url("ibmplexserif-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "IBM Plex Serif";
  src: url("ibmplexserif-bolditalic-webfont.woff2") format("woff2"),
    url("ibmplexserif-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("alegreyasans-medium-webfont.woff2") format("woff2"),
    url("alegreyasans-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("alegreyasans-bold-webfont.woff2") format("woff2"),
    url("alegreyasans-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Alegreya Sans";
  src: url("alegreyasans-black-webfont.woff2") format("woff2"),
    url("alegreyasans-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
